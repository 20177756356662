/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import sprinkle from './assets/sprinkle-pattern.svg'

export default {
  heroContainer: {
    position: `relative`,
    pb: [6, 6],
    pt: [6, 6],
      background: t => `linear-gradient(
        145deg,
        ${t.colors.alpha} 0%,
        ${t.colors.alphaDarker} 100%
      )`
  },
  servicesContainer: {
    px: [3, 4]
  }
}
